<script>
import UserSearchBox from '@/components/UserSearchBox.vue';

export default {
  name: 'LoggerPageUsers',
  components: {
    UserSearchBox,
  },
  props: {
    lid: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    controlsVisible() {
      return this.$store.getters['loggers/data'][this.lid].owner
        || (this.admin && this.$store.getters['user/accessLevel'] === 'OEM');
    },
  },
  data() {
    return {
      // Table loading state
      isBusy: false,

      // Definition of table headers
      fields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'company',
          label: 'Company',
        },
        {
          key: 'writeAccess',
          label: 'Write Access',
        },
        {
          key: 'id',
          label: 'Action',
        },
      ],

      // Add User search
      addUserSearch: {
        name: '',
        email: '',
        company: '',
      },

      // Add user form data
      addUser: {
        userId: '',
        writeAccess: false,
      },

      // Transfer Owner search
      transferOwnerSearch: {
        name: '',
        email: '',
        company: '',
      },

      // Transfer owner form data
      transferOwner: {
        userId: '',
        confirmed: false,
      },

    };
  },
  methods: {
    /**
     * Function that makes the API calls to populate the table
     */
    providerFunction() {
      return this.$http({
        method: 'GET',
        url: `/logger/${this.lid}/users`,
      })
        .then(resp => resp.data);
    },

    /**
     * Search for a user in the Add User dialog
     */
    addUserSearchInput(user) {
      this.addUser.id = user.id;
      this.addUserSearch = user;
    },

    /**
     * Add a user to the logger
     */
    addUserAction() {
      this.$http({
        method: 'POST',
        url: `/logger/${this.lid}/users/${this.addUser.id}`,
        data: {
          writeAccess: this.addUser.writeAccess,
          settings: null,
        },
      })
        .then(() => {
          this.$bvModal.hide('logger-add-user');
          this.addUserSearch.searchEmail = '';
          this.addUserSearch.name = null;
          this.addUserSearch.email = '';
          this.addUserSearch.company = null;
          this.addUserSearch.state = null;
          this.$refs.userTable.refresh();
        })
        .catch((err) => {
          if (err.response.status === 409) {
            this.$bvToast.toast('This user already has access to this logger', {
              title: 'Failed to Add User',
              variant: 'warning',
              toaster: 'b-toaster-bottom-center',
            });
          } else {
            this.$bvToast.toast('Failed to add user to logger', {
              title: 'User Add Failed',
              variant: 'danger',
              toaster: 'b-toaster-bottom-center',
            });
          }
        });
    },

    /**
     * The result of a transfer owner search input
     */
    transferUserSearchInput(user) {
      this.transferOwner.id = user.id;
      this.transferOwnerSearch = user;
    },

    /**
     * Transfer the logger to a new owner
     */
    transferOwnerAction() {
      this.$http({
        method: 'PUT',
        url: `/logger/${this.lid}/owner`,
        data: {
          id: this.transferOwner.id,
        },
      })
        .then(() => {
          // Reload the page
          this.$bvModal.show('logger-page-refresh');
          setTimeout(() => this.$router.go(), 2000);
        });
    },

    /**
     * Set or clear write access for a user
     */
    setWriteAccess(uid, writeAccess) {
      // Set the table busy state
      this.isBusy = true;

      // Now perform the request
      return this.$http({
        method: 'PUT',
        url: `/logger/${this.lid}/users/${uid}`,
        data: {
          writeAccess,
          settings: null,
        },
      })
        .then(() => {
          this.isBusy = false;

          // Trigger a table refresh
          this.$refs.userTable.refresh();

          // Show a toast to confirm this
          this.$bvToast.toast('User updated successfully', {
            title: 'User Updated',
            variant: 'success',
            toaster: 'b-toaster-bottom-center',
          });
        })
        .catch(() => {
          this.isBusy = false;
        });
    },

    /**
     * Delete access for a user
     */
    deleteUserAccess(uid) {
      // Display a confirmation message box
      this.$bvModal.msgBoxConfirm('Are you sure you want to revoke access for this user?')
        .then((resp) => {
          if (resp) {
            this.$http({
              method: 'DELETE',
              url: `/logger/${this.lid}/users/${uid}`,
            })
              .then(() => {
                this.$refs.userTable.refresh();
              });
          }
        });
    },
  },
};
</script>

<template>
  <div>
    <b-card>
      <!-- Table Header -->
      <template slot='header'>
        <b-row align-h='around' align-v='center' no-gutters>
          <b-col cols='6' sm='4' md='3' lg='2'>
            <h5>User Settings</h5>
          </b-col>
          <b-col cols='auto' sm='4' md='6' lg='8'/>
          <b-col cols='6' sm='4' md='3' lg='2'>
            <b-btn :disabled='!controlsVisible'
                    variant='success'
                    class='float-right'
                    v-b-modal.logger-add-user>
                    Add User
                    <fa-icon icon='plus'></fa-icon>
            </b-btn>
          </b-col>
        </b-row>
      </template>

      <!-- Main Table -->
      <b-table ref='userTable'
              :fields='fields'
              :items='providerFunction'
              :busy.sync='isBusy'
              responsive>

        <!-- Custom Rendering for Name Column -->
        <template v-slot:cell(name)="data">
          <b-link v-if='admin' :to="`/oem/user/${data.item.id}`">
            {{ data.value }}
          </b-link>
          <span v-else>
            {{ data.value }}
          </span>
        </template>

        <!-- Custom Rendering for Write Access Column -->
        <template v-slot:cell(writeAccess)="data">
          <b-checkbox v-if='!data.item.owner'
                      :value='true'
                      :unchecked_value='false'
                      :checked='data.value'
                      :disabled='!controlsVisible'
                      @change="setWriteAccess(data.item.id, !data.value)"/>
          <b-badge v-if='data.item.owner' variant='success'>
            Owner
          </b-badge>
        </template>

        <!-- Custom Rendering for final slot -->
        <template v-slot:cell(id)='data'>
          <b-btn v-if='controlsVisible && data.item.owner'
                  variant='danger'
                  v-b-modal.logger-transfer-owner
                  v-b-popover.hover="'Transfer Ownership'">
            <fa-icon icon='exchange-alt' />
          </b-btn>
          <b-btn v-else-if='controlsVisible'
                  variant='danger'
                  @click='deleteUserAccess(data.value)'
                  v-b-popover.hover="'Remove User Access'">
            <fa-icon icon='trash' />
          </b-btn>
        </template>

      </b-table>
    </b-card>
    <!-- End of Main Table -->

    <!-- Add User Dialog -->
    <b-modal id='logger-add-user'
             title='Add User to Logger'
             ok-title='Grant Access'
             ok-variant='success'
             v-bind:ok-disabled='!this.addUserSearch.name'
             @ok.prevent='addUserAction'
             cancel-variant='primary'>

      <!-- User Search Box -->
      <user-search-box v-on:input='addUserSearchInput' />

      <!-- User Details -->
      <b-row class='mt-3'>
        <b-col cols=12>
          Name: <span class='text-muted'>{{ addUserSearch.name }}</span><br>
          Email: <span class='text-muted'>{{ addUserSearch.email }}</span><br>
          Company: <span class='text-muted'>{{ addUserSearch.company }}</span><br>
          Write Access: <b-checkbox v-model='addUser.writeAccess'
                                    v-bind:disabled='!addUserSearch.name'
                                    style="display: inline" />
        </b-col>
      </b-row>
    </b-modal>

    <!-- Transfer Ownership Dialog -->
    <b-modal id='logger-transfer-owner'
             title='Transfer Ownership'
             ok-title='Transfer'
             ok-variant='danger'
             v-bind:ok-disabled='!this.transferOwnerSearch.name || !transferOwner.confirmed'
             @ok.prevent='transferOwnerAction'
             cancel-variant='secondary'>

      <!-- Transfer Ownership Search -->
      <user-search-box v-on:input='transferUserSearchInput' />

      <!-- User Details -->
      <b-row class='mt-3' align-h='center'>
        <b-col cols=12 class='ml-5'>
          Name: <span class='text-muted'>{{ transferOwnerSearch.name }}</span><br>
          Email: <span class='text-muted'>{{ transferOwnerSearch.email }}</span><br>
          Company: <span class='text-muted'>{{ transferOwnerSearch.company }}</span><br>
        </b-col>
      </b-row>

      <!-- Confirmation Checkbox -->
      <b-row class='mt-4'>
        <b-col cols=12>
          <p>
            After transferring this logger, you will remain as a user with write access.
          </p>
          <b-checkbox v-model='transferOwner.confirmed'>
            I confirm that I understand that this action is not reversable and will result in
            all data associated with this user residing within the full control of the user
            listed above.
          </b-checkbox>
        </b-col>
      </b-row>

    </b-modal>

    <!-- Page Refreshing Dialog -->
    <b-modal id='logger-page-refresh'
             hide-header
             body-class='lead text-center'
             hide-footer
             hide-header-close
             no-close-on-backdrop
             no-close-on-esc
             centered>
      Transfer complete.<br>
      Please wait while the page reloads<br>
      <b-spinner variant='primary'/>
    </b-modal>

  </div>
</template>
