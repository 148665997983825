<script>
import Pako from 'pako';
import {
  LMap,
  LTileLayer,
  LMarker,
  LCircle,
  LTooltip,
  LControl,
} from 'vue2-leaflet';

import { mapGetters } from 'vuex';

import MapLocationPicker from '@/components/MapLocationPicker.vue';
import LoggerPageUsers from '@/components/LoggerPageUsers.vue';
import ModemSignalIcon from '@/components/ModemSignalIcon.vue';
import LoggerPageFileTable from '@/components/LoggerPageFileTable.vue';
import LoggerStatusIcon from '@/components/LoggerStatusIcon.vue';

import LoggerStore from '@/mixins/LoggerStore';
import FormatUtils from '@/mixins/FormatUtils';

export default {
  name: 'LoggerPage',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LTooltip,
    LControl,
    MapLocationPicker,
    LoggerPageUsers,
    ModemSignalIcon,
    FileTable: LoggerPageFileTable,
    LoggerStatusIcon,
  },
  mixins: [LoggerStore, FormatUtils],
  data() {
    return {
      // Definition of table headers
      fields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'writeAccess',
          label: 'Write Access',
        },
      ],
      name: {
        editing: false,
        newName: null,
      },
      description: {
        editing: false,
        newDesc: null,
      },
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      zoom: 9,
      error: null,
      refreshing: false,
      commandProgress: [],
      pollingCommandProgress: false,
      meterReadingsVisible: false,
      filesTable: {
      },
      configTable: {
        page: 1,
        itemsPerPage: 5,
        fields: [
          {
            key: 'filename',
            label: 'Filename',
            sortable: true,
          },
          {
            key: 'created',
            label: 'Date Created',
            sortable: true,
            formatter: date => this.$d(date, 'long'),
          },
          {
            key: 'id',
            label: 'Actions',
          },
        ],
      },
      configFileUpload: {
        file: null,
        promptLoad: false,
        inProgress: false,
        result: null,
      },
      settings: {
        useManualLocation: false,
        manualLocation: null,
        useManualPhoneNumber: false,
        manualPhoneNumber: null,
      },
    };
  },
  created() {
    // Show a loading overlay if the data wasn't preloaded
    if (!(this.lid in this.loggers)) {
      this.$store.dispatch('ui/startLoading');
    }
  },
  mounted() {
    // Set the breadcrumbs to the logger name or serial
    if (this.logger && this.logger.name) {
      this.$set(this.$route.meta.breadcrumbs[1], 'text', this.logger.name);
    } else if (this.logger) {
      this.$set(this.$route.meta.breadcrumbs[1], 'text', this.logger.serial);
    }

    // Check whether the logger data was preloaded
    if (!(this.lid in this.loggers)) {
      // This will run if the page has not been pre-loaded
      this.$store.dispatch('ui/startLoading');
      this.$Progress.start();
      this.$store.dispatch('loggers/list');
      this.$store.dispatch('loggers/get', this.lid) // Trigger a logger load
        .then(() => {
          // Set the breadcrumbs to the logger name
          if (this.logger.name) {
            this.$set(this.$route.meta.breadcrumbs[1], 'text', this.logger.name);
          } else {
            this.$set(this.$route.meta.breadcrumbs[1], 'text', this.logger.serial);
          }

          // Finish the progress bar load
          this.$Progress.finish();
          this.$store.dispatch('ui/stopLoading');
          this.name.newName = this.logger.name;
          this.description.newDesc = this.logger.description;

          // Set the correct location on the map
          if (this.logger.manualLocation) {
            this.settings.manualLocation = this.logger.manualLocation.point;
          } else {
            this.settings.manualLocation = null;
          }
          this.settings.useManualLocation = !!this.settings.manualLocation;

          // Setup the phone override settings page
          if (this.logger.phoneOverridden) {
            this.settings.useManualPhoneNumber = true;
            this.settings.manualPhoneNumber = this.logger.phone;
          }
        })
        .catch(() => {
          this.error = 'An Unknown Error Occurred';
          this.$Progress.fail();
          this.$store.dispatch('ui/stopLoading');
        });
    } else {
      // If it was preloaded, hide the progress bar
      this.$Progress.finish();
      this.$store.dispatch('ui/stopLoading');
      this.name.newName = this.logger.name;
      this.description.newDesc = this.logger.description;

      // Set the logger location on the map
      if (this.logger.manualLocation) {
        this.settings.manualLocation = this.logger.manualLocation.point;
      } else {
        this.settings.manualLocation = null;
      }
      this.settings.useManualLocation = !!this.settings.manualLocation;
    }
  },
  computed: {
    ...mapGetters({
      loading: 'ui/loading',
    }),
    lastUpdatedTextStyle() {
      return `h6 ml-1 ${this.dataTooOld(this.logger.last_seen) ? 'text-danger' : 'text-muted'}`;
    },
    displayedLocation() {
      if (this.logger.manualLocation) return this.logger.manualLocation.point;
      if (this.logger.location) return this.logger.location.point;
      return null;
    },
    displayedLocationAccuracy() {
      if (this.logger.manualLocation) return 0;
      if (this.logger.location) return this.logger.location.accuracy || 0;
      return 0;
    },
    manualPhoneFormatted() {
      // Formats the phone number in the manual override box by removing spaces
      if (!this.settings.manualPhoneNumber) return '';
      const formatted = this.settings.manualPhoneNumber.replace(/[^\d|^\+]/g, ''); // eslint-disable-line
      return formatted;
    },
    phoneOverrideSaveDisabled() {
      // The phone number save button should be enabled when the manual set bot is checked,
      // and the phone number in the manual override box is valid, and it does not match the
      // current reported logger phone number.

      return !(
        this.settings.useManualPhoneNumber
        && this.$refs.phoneOverride.valid
        && this.logger.phone !== this.manualPhoneFormatted
      );
    },
    showAdminWarning() {
      // Show an admin warning if the user is an admin, and if the logger is not in their list
      // of accessible loggers.
      if (this.$store.getters['user/accessLevel'] === 'OEM') {
        if (this.$store.getters['loggers/listLength'] === 0) return true;
        const currentUserLoggers = this.$store.getters['loggers/list'];
        return !currentUserLoggers.find(l => l.id === this.lid);
      }
      return false;
    },
  },
  watch: {
    // Triggered when logger command list changes
    cmds: {
      deep: true,
      handler() {
        if (this.cmds.length > 0) {
          // Keep polling whilst there are commands in the queue
          this.pollingCommandProgress = true;
          setTimeout(() => {
            this.$store.dispatch('loggers/getCommands', this.lid);
          }, 5000);
        } else if (this.pollingCommandProgress) {
          this.pollingCommandProgress = false;
          this.$bvToast.toast(
            'Command Completed Successfully',
            {
              title: 'Logger Command',
              toaster: 'b-toaster-bottom-right',
              variant: 'success',
              solid: true,
            },
          );
          setTimeout(() => this.refresh(), 1500);
        }
      },
    },
    'configFileUpload.result': {
      handler() {
        // If the result is null, it was changed by us so stop here
        if (this.configFileUpload.result === null) {
          return;
        }

        // Reset the fields in the objec to their default state
        if (this.configFileUpload.result === true) {
          this.configFileUpload.file = null;
        }
        this.configFileUpload.inProgress = false;

        // Reset the result icon after 2 seconds
        setTimeout(() => {
          this.configFileUpload.result = null;
        }, 2000);
      },
    },
    'settings.useManualPhoneNumber': {
      deep: true,
      handler() {
        if (!this.settings.useManualPhoneNumber) {
          this.$Progress.start();
          this.$store.dispatch('loggers/setManualPhoneNumber', {
            lid: this.lid,
            enableOverride: false,
          })
            .then(() => {
              this.$Progress.finish();
              this.refresh();
            })
            .catch(() => this.$Progress.fail());
        }
      },
    },
  },
  methods: {
    /**
     * Function that makes the API calls to populate the table
     */
    getUserInfo() {
      return this.$http({
        method: 'GET',
        url: `/logger/${this.lid}/users`,
      })
        .then(resp => resp.data);
    },
    /**
     * Check to see whether the last update time was more than an hour ago
     */
    dataTooOld(date) {
      const now = new Date();
      if ((now - date) > 60 * 1000 * 60) {
        return true;
      }
      return false;
    },
    /**
     * Filter files so only those in storage are shown
     */
    filterConfigFiles(file, filter) {
      return (file.onDisk || filter !== 'true') && file.type === 'CONFIGURATION';
    },
    /**
     * Refresh all of the data on this page
     */
    refresh() {
      this.$Progress.start();
      this.refreshing = true;
      this.$store.dispatch('loggers/get', this.lid)
        .then(() => {
          this.$Progress.finish();
          this.refreshing = false;
        })
        .catch(() => {
          this.$Progress.fail();
          this.refreshing = false;
        });
    },
    /**
     * Queue a command for execution on the logger
     */
    executeCommand(command, params) {
      this.$store.dispatch('loggers/issueCommand', {
        lid: this.lid,
        command,
        params,
      })
        .then(() => {
          this.$bvToast.toast(
            'Command Queued Successfully!',
            {
              title: 'Logger Command',
              toaster: 'b-toaster-bottom-center',
              variant: 'success',
              solid: true,
            },
          );
        })
        .catch((err) => {
          if (err.response.status === 409) {
            this.$bvToast.toast(
              'That command is already queued',
              {
                title: 'Logger Command Failed',
                toaster: 'b-toaster-bottom-center',
                variant: 'warning',
                solid: true,
              },
            );
            return;
          }
          this.$bvToast.toast(
            `Return code was ${err.response.status}`,
            {
              title: 'Logger Command Failed',
              toaster: 'b-toaster-bottom-center',
              variant: 'danger',
              solid: true,
            },
          );
        });
    },
    /**
     * Edit the name of a logger on the system
     */
    setName() {
      this.$Progress.start();
      this.$store.dispatch('loggers/setName', {
        lid: this.lid,
        name: this.name.newName,
      })
        .then(() => {
          this.name.editing = false;
          this.$Progress.finish();
        })
        .catch(() => this.$Progress.fail());
    },
    /**
     * Edit the description of a logger on the system
     */
    setDescription() {
      this.$Progress.start();
      this.$store.dispatch('loggers/setDescription', {
        lid: this.lid,
        description: this.description.newDesc,
      })
        .then(() => {
          this.description.editing = false;
          this.$Progress.finish();
        })
        .catch(() => this.$Progress.fail());
    },
    /**
     * Called when the modal shows so the map resizes correctly
     */
    loggerSettingsShown() {
      if (this.settings.useManualLocation) {
        this.$refs.locPick.resize();
      }
    },
    /**
     * Edit the loggers manually overriden location
     */
    setLocationOverride() {
      this.$Progress.start();
      this.$store.dispatch('loggers/setManualLocation', {
        lid: this.lid,
        enableOverride: this.settings.useManualLocation,
        location: this.settings.manualLocation,
      })
        .then(() => {
          this.$Progress.finish();
          this.refresh();
        })
        .catch(() => this.$Progress.fail());
    },
    activateConfig(configName) {
      this.executeCommand('config_load', [configName]);
    },
    uploadConfigFile() {
      // Check that a file has been selected
      if (!this.configFileUpload.file) {
        // If a file has not been specified, just stop
        return;
      }

      // Check that the file extension is .plc
      if (!this.configFileUpload.file.name.endsWith('.plc')) {
        return;
      }

      // Indicate that the upload is in progress
      this.configFileUpload.inProgress = true;

      // Compress the config file
      this.configFileUpload.file.arrayBuffer()
        // Convert into Uint8Array
        .then(array => new Uint8Array(array))
        // Perform the compression
        .then(array => Pako.gzip(array))
        // Package the form data up
        .then((compressed) => {
          const formData = new FormData();
          formData.append('file', new Blob([compressed]), this.configFileUpload.file.name);
          formData.append('created', this.configFileUpload.file.lastModified);
          formData.append('promptLoad', this.configFileUpload.promptLoad);
          formData.append('compressed', true);
          return formData;
        })
        // Now perform the upload
        .then(formData => this.$http({
          method: 'PUT',
          url: `/logger/${this.lid}/files/${encodeURIComponent(this.configFileUpload.file.name)}`,
          data: formData,
        }))
        .then(() => {
          // Display the result and reload the file list in the background
          this.configFileUpload.result = true;
          this.$store.dispatch('loggers/getFiles', this.lid);
        })
        .catch((err) => {
          if (err.response.status === 409) {
            this.configFileUpload.result = false;
            this.$bvToast.toast('A file with that name already exists on the server.', {
              variant: 'danger',
              title: 'Duplicate File',
              toaster: 'b-toaster-bottom-center',
            });
          } else {
            this.configFileUpload.result = false;
            this.$bvToast.toast('An error occurred uploading the file, please try again', {
              variant: 'danger',
              title: 'Error',
              toaster: 'b-toaster-bottom-center',
            });
          }
        });
    },
  },
};
</script>

<template>
  <div>
    <b-container v-if='!loading'>

      <!-- Alert Message for if user is admin and doesn't otherwise have access to logger -->
      <b-alert variant="warning" v-bind:show="showAdminWarning">
        <fa-icon icon='exclamation-triangle'/>
        Take extra care. You are using admin privileges to access a logger that you would
        otherwise not have access to
      </b-alert>

      <!-- Header Row -->
      <b-row>
        <b-col md=8 cols=12>

          <!-- Logger name -->
          <b-row no-gutters>
            <b-col cols=12 sm=8 order=2 order-sm=1>
              <!-- Display an icon -->
              <logger-status-icon :status='logger.status'
                                  :lastupdate='logger.last_seen'
                                  size='2x'
                                  nolabel />
              <!-- Display name -->
              <h2 v-if='!name.editing' style='display: inline'>
                {{ this.logger.name || `SN: ${this.serial}` }}
                <b-link class='h5 ml-1'
                        v-b-popover.hover="'Edit Name'"
                        @click='name.editing = true'>
                  <fa-icon icon='pencil-alt'></fa-icon>
                </b-link>
              </h2>

              <!-- Name Edit Box -->
              <b-input-group v-else>
                <b-input v-model='name.newName' v-on:keyup.enter='setName'></b-input>
                <b-input-group-append>
                  <b-btn variant='danger'
                         @click='name.editing = false'
                         v-bind:disabled='!this.logger.writeAccess'>
                    <fa-icon icon='ban'/>
                  </b-btn>
                  <b-btn variant='success'
                         @click='setName'
                         v-bind:disabled='!this.logger.writeAccess'>
                    <fa-icon icon='check'/>
                  </b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <!-- Action Buttons -->
            <b-col cols=12 sm=4 order=1 order-sm=2>
              <b-button-group class='float-right'>
                <b-dropdown right
                            text="Actions"
                            v-bind:disabled='!this.logger.writeAccess'>
                  <b-dropdown-item @click="executeCommand('start', [])">
                    Start Recording
                  </b-dropdown-item>
                  <b-dropdown-item @click="executeCommand('stop', [])">
                    Stop Recording
                  </b-dropdown-item>
                  <b-dropdown-item @click="executeCommand('restart', [])">
                    Restart Recording
                  </b-dropdown-item>
                </b-dropdown>
                <b-button variant='success' @click="refresh()">
                  <fa-icon icon='sync' v-bind:spin="refreshing"></fa-icon>
                </b-button>
                <b-button variant='gray'
                          v-bind:disabled='!this.logger.writeAccess'
                          v-b-modal.logger-config-modal>
                  <fa-icon icon='cog'></fa-icon>
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>

          <!-- Logger description -->
          <h4 v-if='!description.editing' class='text-muted ml-1'>
            {{ this.logger.description || '(no description set)' }}
            <b-link class='h5 ml-1'
                    v-b-popover.hover="'Edit Description'"
                    @click='description.editing = true'>
              <fa-icon icon='pencil-alt'></fa-icon>
            </b-link>
          </h4>
          <!-- Description Edit Box -->
          <b-input-group v-else>
            <b-input v-model='description.newDesc' v-on:keyup.enter='setDescription'></b-input>
            <b-input-group-append>
              <b-btn variant='success' @click='setDescription'>Set</b-btn>
              <b-btn variant='danger' @click='description.editing = false'>Cancel</b-btn>
            </b-input-group-append>
          </b-input-group>

          <span :class='lastUpdatedTextStyle'
                style='white-space: nowrap'>
                Last Updated:
                {{ logger.last_seen && $d(new Date(logger.last_seen), 'long') || '(never)' }}
          </span>

          <!-- Meter values -->
          <b-container fluid class="mt-1 mb-2">
            <b-row no-gutters>
              <b-col v-for="value in meterReadings.slice(0, 8)"
                     :key='value.channel'
                     cols=6
                     md=4
                     lg=3>
                <b-card :header='`${value.channel}: ${value.description}`'
                        class='meter-card'
                        body-class='meter-body'
                        header-class='meter-header'
                        header-bg-variant='ui-yellow'
                        header-text-variant='dark'>
                  <b-card-title>{{ value.value }}</b-card-title>
                </b-card>
              </b-col>
              <b-collapse id='logger-meter-collapse'
                          v-model='meterReadingsVisible'>
                <b-row no-gutters>
                  <b-col v-for="value in meterReadings.slice(8)"
                        :key='value.channel'
                        cols=6
                        md=4
                        lg=3>
                    <b-card :header='`${value.channel}: ${value.description}`'
                            class='meter-card'
                            body-class='meter-body'
                            header-class='meter-header'
                            header-bg-variant='ui-yellow'
                            header-text-variant='dark'>
                      <b-card-title>{{ value.value }}</b-card-title>
                    </b-card>
                  </b-col>
                </b-row>
              </b-collapse>
              <b-btn variant='outline-primary'
                     class='mt-1'
                     v-if='this.meterReadings.length > 8'
                     block v-b-toggle.logger-meter-collapse>
                {{ this.meterReadingsVisible ? 'Collapse' : 'Expand' }} Meter Readings
              </b-btn>
            </b-row>
            <span v-if='dataTooOld(logger.last_seen)' :class='lastUpdatedTextStyle'>
              Warning: Data is more than an hour old so may not be reliable
            </span>
          </b-container>
          <!-- End meter values -->

        </b-col>

        <!-- Logger Actions Button -->
        <b-col md=4 cols=12>
          <b-row no-gutters>
            <b-col cols=12>
            </b-col>
          </b-row>
          <!-- Map -->
          <b-row no-gutters class='mt-2' align-h="center">
            <b-col cols=12 style='min-height: 300px'>
              <div v-if='!!logger.location || !!logger.manualLocation'
                         style='height: 100%;'>
                <l-map
                  style='height:100%; width: 100%; z-index: 0'
                  :zoom="zoom"
                  :center="displayedLocation">
                  <l-tile-layer :url='url' />
                  <l-control class="location-modal">
                    <b-button variant='outline-primary'
                      v-bind:disabled='!this.logger.writeAccess'
                      v-b-modal.location-modal>
                      <fa-icon icon='cog'></fa-icon>
                    </b-button>
                  </l-control>
                  <l-marker
                    :visible=true
                    :lat-lng='displayedLocation'>
                      <l-tooltip :content='logger.name || logger.serial' />
                    </l-marker>
                    <l-circle
                      :lat-lng='displayedLocation'
                      :radius='displayedLocationAccuracy'
                      :visible='displayedLocationAccuracy > 0'
                      color='blue'
                      :weight='1' />
                </l-map>
                <span v-if="logger.location && logger.location.source == 'GPS'"
                      class='text-muted'>
                  Location provided by GPS
                </span>
                <span v-else-if="logger.location && logger.location.source == 'CELLULAR'"
                      class='text-muted'>
                  Approximate position based on cell towers.
                  Location powered by <a href='https://unwiredlabs.com/'>Unwired Labs</a>
                </span>
              </div>
              <div v-else class='lead text-center'
                    style='background-color: lightgrey; height: 100%'>
                No location data
                <br/>
                <br/>
                <br/>
                <br/>
                <b-button class='center' variant='outline-primary'
                  v-bind:disabled='!this.logger.writeAccess'
                  v-b-modal.location-modal>
                  <fa-icon icon='cog'></fa-icon>
                  Location Settings
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- End of Header -->

      <!-- Main Body -->
      <b-row class='mt-3'>

        <!-- File List -->
        <b-col lg='8' class='mb-3'>
          <file-table type-filter='PLAYBACK' />
        </b-col>

        <!-- Side Columns -->
        <b-col cols='12' lg='4' >
          <b-card class='mb-2'>
            <template slot='header'>
              <b-row align-v='center' align-h='between' class='status-header' no-gutters>
                <b-col cols='4'>
                  <span>Status</span>
                </b-col>
                <b-col cols='5'>
                  <modem-signal-icon v-if='logger.modemSignal != null'
                                    :bars='logger.modemSignal'
                                    :offline='dataTooOld(logger.last_seen)'
                                    class='float-right'>
                    {{ logger.modemCarrier }}
                  </modem-signal-icon>
                </b-col>
              </b-row>
            </template>
            <!-- Status Table -->
            <b-table-simple>
              <b-tbody>
                <b-tr>
                  <b-td>Status</b-td>
                  <b-td v-if='logger.status'>{{ formatStatus(logger.status) }}</b-td>
                  <b-td v-else>unavailable</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Battery Voltage</b-td>
                  <b-td v-if='logger.status'>{{ logger.batteryVolts }} V</b-td>
                  <b-td v-else>unavailable</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Ambient Temperature</b-td>
                  <b-td v-if='logger.status'>{{ logger.ambientTemp }} &deg;C</b-td>
                  <b-td v-else>unavailable</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Firmware</b-td>
                  <b-td v-if='logger.status'>{{ logger.firmware }}</b-td>
                  <b-td v-else>unavailable</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Free Memory</b-td>
                  <b-td v-if='logger.status'>{{ logger.freeMemory }} %</b-td>
                  <b-td v-else>unavailable</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
          <b-card header='Quota Usage' class='mb-2'>
            {{ formatFileSize(logger.quota.used) }}
            used of
            {{ logger.quota.total !== null ? formatFileSize(logger.quota.total) : 'unlimited' }}
            <b-progress v-bind:value='logger.quota.used'
                        v-bind:max='logger.quota.total || 0'
                        class='mt-3'/>
          </b-card>
          <!--<b-card header='Subscription' class='mb-2'>
            Subscription due to expire on...
          </b-card>-->
          <b-card class='mb-2'>
            <template slot='header'>
              <b-row align-h='around' align-v='center' no-gutters>
                <b-col cols='6' sm='4' md='3' lg='2'>
                  Users
                </b-col>
                <b-col cols='auto' sm='4' md='6' lg='8'/>
                <b-col cols='6' sm='4' md='3' lg='2'>
                  <b-button size='sm' variant='outline-primary' class="float-right"
                    v-bind:disabled='!this.logger.writeAccess'
                    v-b-modal.users-modal>
                    <fa-icon icon='cog'></fa-icon>
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <b-table ref='userTable'
              :fields='fields'
              :items='getUserInfo'
              :busy.sync='isBusy'
              responsive
              small
              thead-class="d-none">

        <!-- Custom Rendering for Name Column -->
        <template v-slot:cell(name)="data">
          {{ data.value }}
        </template>

        <!-- Custom Rendering for Write Access Column -->
        <template v-slot:cell(writeAccess)="data">
          <b-badge v-if='data.item.owner' variant='success'>
            Owner
          </b-badge>
          <b-badge v-if='data.item.writeAccess' variant='primary'>
            Write access
          </b-badge>
          <b-badge v-else variant='gray'>
            Read access
          </b-badge>
        </template>
      </b-table>
        <!-- End of Users Table -->
          </b-card>
        </b-col>
      </b-row>

      <!-- Command Processing Toast -->
      <b-toast id='logger-command-processing'
               v-if='this.cmds.length > 0'
               toaster='b-toaster-bottom-right'
               variant='accent'
               visible
               is-status
               no-auto-hide
               no-close-button
               solid
               no-hover-pause>
        <template slot='toast-title'>
          <b-spinner small class='mr-3'/> Command in Progress
        </template>
        <b-row no-gutters>
          <b-col cols='auto' md=4>
            <b>Command:</b>
          </b-col>
          <b-col cols='auto'>
            <span v-if="this.cmds[0].command === 'start'">Start Recording</span>
            <span v-else-if="this.cmds[0].command === 'stop'">Stop Recording</span>
            <span v-else-if="this.cmds[0].command === 'restart'">Restart Recording</span>
            <span v-else-if="this.cmds[0].command === 'config_load'">
              Activate {{ this.cmds[0].params[0] }}
            </span>
            <span v-else>{{ this.cmds[0].command }}</span>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col cols='auto' md=4>
            <b class='mr-2'>Status:</b>
          </b-col>
          <b-col cols='auto'>
            <span v-if='!this.cmds[0].started'>Waiting for logger...</span>
            <span v-else>Running</span>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col>
            <b v-if='this.cmds.length > 1'>
              {{ this.cmds.length - 1 }} more commands queued after this
            </b>
          </b-col>
        </b-row>
      </b-toast>
    </b-container>

    <!-- Errors get displayed below here -->
    <h3 v-else-if='error'>{{ error }} </h3>

    <!-- Location Dialog -->
    <b-modal id='location-modal'
      v-if='!loading'
      title='Location Settings'
      size='xl'
      :visible="visible"
      @change="loggerSettingsShown"
      hide-footer>
      <!-- Manual Location Picker -->
      <b-row>
        <b-col cols=10 lg=6>
          <b-row class='mt-4' no-gutters>
            <b-checkbox v-model='settings.useManualLocation' switch>
              Manually override logger location
            </b-checkbox>
            <p class='text-muted'>
              Click the map and drag the icon to set the logger location.
            </p>
            <b-col cols=12>
              <b-btn @click='setLocationOverride()'>Save</b-btn>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols=12 lg=6>
          <map-location-picker ref='locPick'
            v-if='settings.useManualLocation'
            :center='displayedLocation || undefined'
            :markerLocation='logger.location ? logger.location.point : undefined'
            :zoom='displayedLocation ? 9 : 2'
            v-model='settings.manualLocation' />
        </b-col>
      </b-row>
    </b-modal>
    <!-- End of location modal -->

    <!-- Configuration Dialog -->
    <b-modal id='logger-config-modal'
      v-if='!loading'
      title='Configuration Settings'
      size='xl'
      :visible="visible"
      @change="loggerSettingsShown"
      hide-footer>
      <b-container fluid class='mt-2'>
        <b-row>
          <b-col cols=12 lg=9>
            <!-- File Table -->
            <file-table title='Configuration Files'
                        type-filter='CONFIGURATION'
                        v-on:activate-config='activateConfig' />
          </b-col>
          <b-col cols=12 lg=3>
            Upload new config file:
            <b-form-file accept=".plc" v-model='configFileUpload.file' />
            <b-checkbox class='mt-1' v-model='configFileUpload.promptLoad' switch>
              Activate config for start of next session
            </b-checkbox>
            <b-btn class='mt-1'
                   @click='uploadConfigFile()'
                   v-bind:disabled='!configFileUpload.file || configFileUpload.inProgress'
                   block>
              <template v-if='configFileUpload.inProgress'>
                <b-spinner small></b-spinner> Uploading...
              </template>
              <template v-else-if='configFileUpload.result === true'>
                <fa-icon icon='check' :style="{ color: 'green' }"/> Upload
              </template>
              <template v-else-if='configFileUpload.result === false'>
                <fa-icon icon='times' :style="{ color: 'red' }"/> Upload
              </template>
              <template v-else>
                Upload
              </template>
            </b-btn>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- End of config modal -->

    <!-- Users Dialog -->
    <b-modal id='users-modal'
             v-if='!loading'
             :title='this.logger.serial'
             size='xl'
             :visible="visible"
             @change="loggerSettingsShown"
             hide-footer>
      <logger-page-users :lid='this.lid'/>
    </b-modal>
    <!-- End of users modal -->
  </div>
</template>

<style scoped>
  .meter-header {
    padding: 2px;
    padding-left: 6px;
  }

  .meter-header div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .meter-card {
    height: 100%;
  }

  .meter-body {
    padding-top: 0.5em;
    padding-bottom: 0.1em;
    padding-left: 0.1em;
    padding-right: 0.1em;
    text-align: center;
  }

  .status-header {
    padding-top: -5em;
    padding-bottom: -5em;
  }
</style>
